<template>
    <!-- Page title -->
    <section class="title-bg-dark">
        <div class="container">
            <div class="page-title text-white text-center">
                <h2 data-animate="fadeInUp" data-delay="1.2">{{ PageSort }}</h2>
                <h1 data-animate="fadeInUp" data-delay="1.3">{{ PageTitle }}</h1>
                <p data-animate="fadeInUp" data-delay="1.4">{{ PageDes }}</p>
            </div>
        </div>
    </section>
    <!-- End of Page title -->
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    PageSort: String,
    PageTitle: String,
    PageDes: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
